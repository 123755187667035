<template>
  <section class="applicant-interview-details">
    <p class="applicant-interview-details__body">
      <span
        class="applicant-interview-details__text-line"
        data-test="interview-date"
      >
        {{ $t(`${langPath}.interview`, { employerName: employerName }) }}
        {{ candidateFirstName }}:
        {{ startAt }}
      </span>
      <span
        v-if="inPerson"
        class="applicant-interview-details__text-line"
        data-test="interview-medium"
      >
        {{ closestInterview.mediumInfo }}
      </span>
      <span
        v-else
        class="applicant-interview-details__text-line"
        data-test="interview-medium"
      >
        Link:
        <a
          :href="closestInterview.mediumInfo"
          class="applicant-interview-details__link applicant-interview-details__link--anchor"
        >
          {{ closestInterview.mediumInfo }}
        </a>
      </span>
    </p>
    <p class="applicant-interview-details__body">
      <ev-button
        class="applicant-interview-details__link"
        color="blue"
        variant="secondary"
        data-test="interview-cancel"
        size="small"
        @click="handleCancelInterview"
      >
        {{ $t(`${langPath}.cancel`) }}
      </ev-button>
    </p>
  </section>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
import { APPLICANTS } from '@/store/namespaces'
import { TheNotificationCardList } from '@revelotech/everest'
import EvButton from '@revelotech/everestV2/EvButton'

const { mapGetters, mapActions } = createNamespacedHelpers(APPLICANTS)

export default {
  name: 'ApplicantInterviewDetails',
  components: {
    EvButton
  },
  props: {
    candidateName: {
      type: String,
      required: true
    },
    employerName: {
      type: String,
      required: true
    },
    applicantId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    ...mapGetters(['closestInterview']),
    candidateFirstName () {
      return this.candidateName?.split(' ')[0]
    },
    startAt () {
      return this.$moment(this.closestInterview?.scheduledStartAt)
        .format('LLLL')
    },
    inPerson () {
      return ['0', 'in_person'].includes(this.closestInterview.medium)
    }
  },
  methods: {
    ...mapActions(['cancelInterview']),
    async handleCancelInterview () {
      if (!window.confirm(this.$t(`${this.langPath}.cancelConfirmation`))) {
        return
      }

      await this.cancelInterview(this.closestInterview.id)

      TheNotificationCardList.createNotification({
        message: this.$t(`${this.langPath}.cancelSuccess`),
        modifier: 'success'
      })
    }
  }
}
</script>

<style lang="scss">
.applicant-interview-details {
  &__body {
    @extend %body-text1;

    margin-top: $base*3;
  }

  &__text-line {
    display: block;
  }

  &__link--anchor {
    color: $blue;
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>
