<template>
  <section v-if="applicant" class="applicant-interview">
    <component
      :is="interviewState"
      :candidate-name="candidateName"
      :employer-name="employerName"
      :applicant-id="applicant.id"
    />
  </section>
</template>

<script>
import ApplicantInterviewSchedule from './components/ApplicantInterviewSchedule'
import ApplicantInterviewDetails from './components/ApplicantInterviewDetails'
import ApplicantInterviewPast from './components/ApplicantInterviewPast'
import { createNamespacedHelpers } from 'vuex'
import { APPLICANTS } from '@/store/namespaces'

const { mapActions, mapState, mapGetters } = createNamespacedHelpers(APPLICANTS)

export default {
  name: 'ApplicantInterview',
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    ...mapState(['applicantId']),
    ...mapGetters(['applicant', 'closestInterview']),
    candidate () { return this.applicant?.candidate },
    candidateName () { return this.candidate?.name },
    employerName () { return this.closestInterview?.employerName },
    interviewState () {
      const endAt = this.closestInterview?.scheduledEndAt

      if (this.closestInterview && (new Date(endAt)) > (new Date())) {
        return ApplicantInterviewDetails
      }
      if (this.closestInterview) {
        return ApplicantInterviewPast
      }
      return ApplicantInterviewSchedule
    }
  },
  watch: {
    applicantId: {
      immediate: true,
      handler () {
        this.getInterviews()
      }
    }
  },
  methods: {
    ...mapActions(['getInterviews'])
  }
}
</script>

<style lang="scss">
.applicant-interview {
  margin-top: $base*6;
}
</style>
