<template>
  <section class="applicant-interview-schedule">
    <p class="applicant-interview-schedule__text" data-test="text">
      {{ $t(`${langPath}.text`, { candidateName: candidateFirstName }) }}
    </p>

    <ev-button
      variant="secondary"
      color="blue"
      class="applicant-interview-schedule__button"
      data-ga-id="clicked_interview_new"
      data-test="button"
      @click="openSchedule"
    >
      {{ $t(`${langPath}.schedule`) }}
    </ev-button>
  </section>
</template>

<script>
import EvButton from '@revelotech/everestV2/EvButton'

export default {
  name: 'ApplicantInterviewSchedule',
  components: {
    EvButton
  },
  props: {
    candidateName: {
      type: String,
      required: true
    },
    applicantId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    candidateFirstName () {
      return this.candidateName?.split(' ')[0]
    }
  },
  methods: {
    openSchedule () {
      const path =
        `#/applicants/${this.applicantId}/interviews/new`

      window.open(path, '_blank')
    }
  }
}
</script>

<style lang="scss">
.applicant-interview-schedule {
  &__button {
    margin-top: $base*4;
  }

  &__text {
    @extend %body-text1;

    margin-top: $base*3;
  }
}
</style>
